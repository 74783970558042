<template>

  <div class="login">

    <div class="left_side" :style="onload ? 'left: 70px; opacity: .7;' : null">
        <div class="main_v8">
            Please enter your new password<br><br>
            <input class="fields" type="password" v-model="newPassword"><br><br>
            <br><br>
            <button class="submit_login" @click="newPasswordEntered(newPassword)" >RESET
                PASSWORD
            </button>
        </div>

    </div>

    <div class="right_side" :style="onload ? 'right: 70px; opacity: .7;' : null">
      <div class="top"></div>
      <div class="bottom"></div>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { submitNewPassword } from "supertokens-web-js/recipe/emailpassword";
  let newPassword = ref("");

  async function newPasswordEntered(newPassword) {
    try {
        let response = await submitNewPassword({
            formFields: [{
                id: "password",
                value: newPassword
            }]
        });

        if (response.status === "FIELD_ERROR") {
            response.formFields.forEach(formField => {
                if (formField.id === "password") {
                    // New password did not meet password criteria on the backend.
                    window.alert(formField.error)
                }
            })
        } else if (response.status === "RESET_PASSWORD_INVALID_TOKEN_ERROR") {
            // the password reset token in the URL is invalid, expired, or already consumed
            window.alert("Password reset failed. Please try again")
            window.location.assign("/auth") // back to the login scree.
        } else {
            window.alert("Password reset successful!")
            window.location.assign("/auth")
        }
    } catch (err) {
        if (err.isSuperTokensGeneralError === true) {
            // this may be a custom error message sent from the API by you.
            window.alert(err.message);
        } else {
            window.alert("Oops! Something went wrong.");
        }
    }
  }
</script>

<style lang="scss" scoped>
.main{
      top: 0px;
      bottom: 0px;
      position: absolute;
      left:0px;
      right:0px;
      text-align: center;
      border-radius:10px;
      padding:50px;
      font-size:17px;
  }
  input {
        width: 80%;
        border: 1px solid #333;
        height: 40px;
        box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.02);
        text-indent: 16px;
        color: #787878;
        font-size: 14px;
        position: relative;
      }

      input::placeholder {
        color: #787878;
        font-size: 14px;
        font-family: 'Inter', sans-serif;
      }

      button {
        width: 200px;
        height: 48px;
        background-color: #006C3E;
        font-size: 14px;
        color: #fff;
      }
.login {
  background-color: #f8f8f8;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  .left_side, .right_side {
    transition: all 0.3s ease;
  }

  .left_side {
    position: absolute;
    left: 0;
    width: 50%;
    height: 100%;
    background-color: #f8f8f8;

    .form {
      overflow: hidden;

      .fields {
        width: 800px;
        max-width: calc(100% * 2);
        position: relative;
        text-align: left;
        transition: all .3s ease;
      }

      .forgot {
        font-size: 12px;
        text-align: right;
        cursor: pointer;
        color: #006C3E;
        margin: 8px 0;
        width: 50%;
        max-width: 400px;
      }

      .err_login, .err_pass {
        border: 1px solid #CD180A;
      }

      .err_message {
        margin: 0;
        font-size: 11px;
        text-align: left;
        line-height: 16px;
        margin-top: -10px;
        margin-bottom: 10px;
        color: #CD180A;
        text-indent: 10px;
      }

      label {
        position: relative;
        width: 50%;
        margin-top: 16px;
        display: block;
        left: 0;
      }

      .eye {
        display: inline-block;
        height: 40px;
        width: 40px;
        position: absolute;
        right: 5px;
        background-image: url(../../public/img/password.png);
        background-position: center;
        background-repeat: no-repeat;

        span {
          display: inline-block;
          height: 27px;
          width: 2px;
          background-color: #787878;
          transform: rotate(60deg);
          position: relative;
          top: 7px;
          right: -18px;
        }
      }

      > p {
        color: #787878;
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 40px;
      }

      h2 {
        font-size: 32px;
        line-height: 40px;
        height: 40px;
        margin-bottom: 16px;
      }

      position: absolute;
      left: 50%;
      top: 50%;
      margin-top: -180px;
      margin-left: -200px;
      width: 400px;
      text-align: center;

      input, button {
        max-width: 400px;
        box-sizing: border-box;
        border: none;
        outline: none;
        border-radius: 8px;
        transition: all .3s;
      }

      button:hover {
        background-color: #f2f8f5;
        color: #006C3E;
        border: 1px solid #006C3E;
      }

      input {
        width: 100%;
        border: 1px solid #fff;
        height: 40px;
        box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.02);
        text-indent: 16px;
        color: #787878;
        font-size: 14px;
        position: relative;
      }

      input::placeholder {
        color: #787878;
        font-size: 14px;
        font-family: 'Inter', sans-serif;
      }

      button {
        width: 50%;
        height: 48px;
        background-color: #006C3E;
        font-size: 14px;
        color: #fff;
      }
    }
  }

  .right_side {
    position: absolute;
    width: 50%;
    height: 100%;
    right: 0;
    background-color: #006C3E;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(../../public/img/w7login.png);

    .top {
      position: absolute;
      top: 0;
      width: 100%;
      height: 40%;
      transform: rotate(180deg);
    }

    .bottom {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 40%;
    }

    .top, .bottom {
      background-image: url(../../public/img/vectors.png);
      background-position: bottom;
      background-repeat: repeat-x;
    }
  }
}

@media screen and (max-width: 1024px) and (min-width: 600px) and (max-height: 1366px) and (min-height: 961px) and (orientation: portrait) {
  .login {
    .left_side {
      background-image: url(../../public/img/vectors2.png);
      background-position: top;
      background-repeat: repeat-x;
      width: 100%;

      .form {
        background-image: url(../../public/img/w7_green.svg);
        background-size: 58px;
        background-position: center top;
        background-repeat: no-repeat;
        padding-top: 50px;
        top: 48%;
      }
    }

    .right_side {
      display: none;
    }
  }
}

@media screen and (max-width: 599px) and (orientation: portrait) {
  .login {
    .left_side {
      background-image: url(../../public/img/vectors2.png);
      background-position: center -60px;
      background-repeat: repeat-x;
      width: 100%;
      padding: 0 15px;
      box-sizing: border-box;

      .form {
        background-image: url(../../public/img/w7_green.svg);
        background-size: 44px;
        background-position: center top;
        background-repeat: no-repeat;
        padding-top: 50px;
        width: inherit;
        box-sizing: border-box;
        position: relative;
        margin-top: 230px;
        text-align: center;
        top: auto;
        left: auto;
        margin-left: auto;

        > p {
          color: #787878;
          font-size: 14px;
          line-height: 24px;
          margin-bottom: 16px;
          margin-top: 8px;
        }

        h2 {
          font-size: 24px;
          line-height: 32px;
          height: 32px;
          margin-bottom: 8px;
        }
      }
    }

    .right_side {
      display: none;
    }
  }
}

@media screen and (max-height: 500px) and (orientation: landscape) {
  .login {
    .left_side {
      width: 100%;
    }

    .right_side {
      display: none;
    }
  }
}

.slide-err-enter-active,
.slide-err-leave-active {
  transition: all 0.3s ease-out;
}

.slide-err-enter-from,
.slide-err-leave-to {
  margin-top: -30px;
}
</style>
